<template>
    <div>
        <slot name="trigger" v-bind:openFileManager="openFileManager" v-bind:canAddFiles="canAddFiles" v-if= !manager.source>
             <!-- default content -->
            <button
                class="btn-filemanager"
                type="button"
                @click="openFileManager"
                v-if="canAddFiles"
                
            >
                <icon name="camera" class="block w-16 h-16"></icon> add images
            </button>
        </slot>

        <slot name="hint" v-bind:canAddFiles="canAddFiles" v-bind:maxUploadSize="manager.uploader.maxUploadSize" v-bind:maxNbOfFiles="maxNbOfFiles" v-if= !manager.source>
            <span class="form-hint" v-if="canAddFiles">
                Select {{ maxNbOfFiles }} image<template v-if="maxNbOfFiles > 1">s</template>.
                The max upload file size is {{ manager.uploader.maxUploadSize }}MB.
            </span>
        </slot>

        <slot class="w-full" name="preview" v-bind:selectedFiles="manager.selectedFiles" v-bind:removeFile="removeFile" v-bind:editFile="editFile">
            <div class="w-full cursor-move mt-4" v-if="manager.selectedFiles.length">
                <p class="my-2 font-bold">Images to be uploaded:</p>
                <draggable
                    :list="manager.selectedFiles"
                    item-key="file_uuid"
                    :component-data="{tag: 'div', class: 'w-full grid grid-cols-4 gap-4', name: 'grid'}"
                    @start="dragging = true"
                    @end="dragging = false"
                >
                    <template v-slot:item="{element: file}">
                        <div class="cell relative">
                            <img v-if= !manager.source :src="file.thumbnail_url" />
                            <img v-if= manager.source :src="file">
                            <div
                                class="absolute w-full h-12 bottom-0 left-0 flex block bg-gray-700 bg-opacity-50 opacity-0"
                                v-if= !manager.source
                            >
                                <span class="w-1/2 flex items-center justify-center">
                                    <icon name="close" class="w-6 h-6 text-red-500 cursor-pointer" @click="removeFile(file)"></icon>
                                </span>

                                <span v-if="editable" class="w-1/2 flex items-center justify-center">
                                    <icon name="pencil2" class="w-6 h-6 text-blue-500 cursor-pointer" @click="editFile(file)"></icon>
                                </span>
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
            <div class="w-full cursor-move mt-4" v-if="!manager.selectedFiles.length">
                <div class="h-24 w-24 bg-gray-100 flex items-center justify-center">
                    <Icon name="photo" class="h-5 w-5 text-gray-300" />
                </div>
                <p class="my-2 font-bold text-gray-500">No image available</p>
            </div>
        </slot>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import EventBus from "@/utils/EventBus";
import Icon from "@/components/ui/Icon";
import {v4} from "uuid";
import { mapActions, mapGetters } from "vuex";
import {orderBy} from "lodash-es";
import AuthMixin from "@/components/auth/AuthMixin";

export default {
    mixins: [ AuthMixin ],
    components: { Icon, draggable },
    props: {
        /**
         * File manager props for both upload and library features.
         *
         * - value : v-model
         * - communityId: The communityId that the image will be linked to.
         * - maxNbOfFiles: the max number of file (uploaded and/ord selected from library) allowed
         */
        source: {
            type: String,
        },

        value: {
            type: [ String, Array ],
            required: true,
        },
        communityId: {
            required: false,
            type: String,
            default: null
        },
        maxNbOfFiles: {
            type: Number,
            required: false,
            default: 1
        },
        /**
         * File manager library props
         *
         * - library: an array of files chosen from the global files library.
         */
        library: {
            type: Array,
            required: false,
            default: () => { return [] }
        },
        libraryFilters: {
            type: Array,
            required: false,
            default: () => { return [] }
        },
        libraryDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        /**
         * File manager upload props.
         *
         * - mainDirectory: Service base directory where the file is stored.
         * - copyToDirectory: One or multiple base directories where the file copies are stored.
         * - accepts: upload mime types allowed
         * - maxUploadSize: Max upload size per file.
         * - cropperOptions: Options for image editing.
         */
        mainDirectory: {
            type: String,
            required: false,
            default: null
        },
        copyToDirectory: {
            type: Array,
            required: false,
            default: () => { return [] }
        },
        accepts: {
            type: String,
            required: false,
            default: 'image/*', // 'images/*', 'video/*', 'file/csv', 'file/xls' ...
        },
        maxUploadSize: {
            type: Number,
            required: false,
            default: 20 // 20Mb
        },
         /**
         * File manager cropper props
         *
         * - cropperOptions: an array for cropper visual options
         */
        cropperOptions: {
            type: Object,
            required: false,
            default: () => {
                return {
                    aspectRatio: 16 / 9,
                    cropBoxResizable: true,
                    zoomOnWheel: false,
                };
            }
        },
         /**
         * Disable cropper tool
         *
         * - noCropper: a boolean value to disable the cropper option after upload a new image
         */
        noCropper: {
          type: Boolean,
          required: false,
          default: false,
        },
        editable: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    emits: ['input', 'uploaded-to-s3'],
    data: () => {
        return {
            dragging: false,
            manager: {}
        }
    },
    computed: {
        ...mapGetters({
            getLibraryFileById: 'files/getLibraryFileById',
            getLibraryFilesByIds: 'files/getLibraryFilesByIds'
        }),
        canAddFiles() {
            return this.manager.maxNbOfFiles > this.manager.selectedFiles.length;
        }
    },
    watch: {
        // update the v-model value with selected files ids
        manager: {
            handler: function() {
                let selectedFiles = [];
                if(this.manager.selectedFiles) {
                    this.manager.selectedFiles.forEach((file) => {
                        selectedFiles.push(file.file_uuid);
                    });
                }
                this.$emit('input', selectedFiles);
            },
            deep: true,
            immediate: true
        },
        // update the manager communityId in the store
        communityId: {
            handler: function () {
                this.updateManagerCommunityId({communityId: this.communityId, managerId: this.manager.managerId });
            },
            immediate: true
        },
        // update the manager uploader main directory in store
        mainDirectory: {
            handler: function () {
                if(this.mainDirectory)
                    this.updateManagerUploaderMainDirectory( { mainDirectory: this.mainDirectory, managerId: this.manager.managerId })
            },
            immediate: true
        }
    },
    methods: {
        ...mapActions({
            createManager: 'files/createManager',
            removeManagerById: 'files/removeManagerById',
            updateManagerCommunityId: 'files/updateManagerCommunityId',
            removeFileFromManagerSelectedFiles: 'files/removeFileFromManagerSelectedFiles',
            updateManagerUploaderMainDirectory: 'files/updateManagerUploaderMainDirectory'
        }),
        openFileManager() {
            EventBus.emit('open-modal-file-manager', {
                managerId: this.manager.managerId,
                noCropper: this.noCropper
            });
        },
        getSelectedFiles() {
            let selectedFiles = [];

            if(!this.value)
                selectedFiles = [];
            else if(Array.isArray(this.value)) {
                this.value.forEach( item => {
                    let file = this.getLibraryFileById(item);
                    if(file)
                        selectedFiles.push(file);
                });
            }
            else
                selectedFiles = this.getLibraryFilesByIds([this.value]);

            if(this.manager.source)
                selectedFiles = this.value

            return selectedFiles;
        },
        removeFile(file) {
            this.removeFileFromManagerSelectedFiles( { managerId: this.manager.managerId, file: file } )
        },
        editFile(file) {
            EventBus.emit('open-modal-file-manager', {
                managerId: this.manager.managerId,
                file_uuid: file.file_uuid,
                step: 'edit-image'
            });
        }
    },
    created() {
        // sort library by date DESC
        const library = orderBy(this.library, ['created_at'], ['desc']);

        this.manager = {
            managerId: v4(),
            communityId : this.communityId ? this.communityId : this.community?.id,
            maxNbOfFiles: this.maxNbOfFiles,
            library,
            libraryFilters: this.libraryFilters,
            libraryDisabled: this.libraryDisabled,
            uploader: {
                mainDirectory: this.mainDirectory,
                copyToDirectory: this.copyToDirectory,
                accepts: this.accepts,
                maxUploadSize: this.maxUploadSize,
                cropperOptions: this.cropperOptions,
                uploadedFiles: [],
            },
            source: this.source,
            selectedFiles: this.getSelectedFiles(),
        }

        this.createManager(this.manager);
    },
    mounted() {
        EventBus.on('uploaded-to-s3', (payload) => {
            if(payload.managerId === this.manager.managerId) {
                this.$emit('uploaded-to-s3', payload.file);
            }
        })

    },
    beforeUnmount() {
        this.removeManagerById(this.manager.managerId);
    },
}
</script>

<style scoped>
    .grid-move {
        transition: all 0.3s;
    }
    .cell:hover div {
        opacity: 100;
    }
</style>

