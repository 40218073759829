<template>
    <div class="h-full">
        <loader
            :loading="loading"
            :backdrop="true"
        />

        <div
            v-if="community"
            class="flex justify-between h-full w-full"
        >
            <!-- services & media thumbnails listing -->
            <div class="w-full h-full overflow-x-hidden overflow-y-scroll relative">
                <div
                    class="sticky pb-4 bg-white z-10 flex justify-between"
                    style="top: -0.5px"
                >
                    <div class="w-1/4 flex justify-between items-center">
                        <span
                            v-for="service in services"
                            :key="service.value"
                            class="text-gray-500 cursor-pointer pb-2 mb-2 border-b border-transparent border-b-3 font-semibold"
                            :class="selectedService === service.value ? 'text-blue-500 border-blue-500' : ''"
                            @click="selectedService = service.value"
                        >
                            {{ service.label }}
                        </span>
                    </div>
                    <file-manager
                        v-if="canUpload"
                        v-model="files"
                        :library-disabled="true"
                        :main-directory="selectedService === 'marketing' ? null : selectedService"
                        :max-nb-of-files="20"
                        :max-upload-size="20"
                        :community-id="selectedCommunityId"
                        :cropper-options="{ aspectRatio: 16 / 9, cropBoxResizable: true, zoomOnWheel: false }"
                        @uploaded-to-s3="getMedias"
                    >
                        <template #preview>
                            <div />
                            <!-- disable preview of uploaded files -->
                        </template>
                        <template #hint>
                            <div />
                            <!-- disable hint -->
                        </template>
                    </file-manager>
                </div>
                <div class="w-full flex flex-wrap">
                    <div
                        v-for="(media, i) in medias"
                        :key="i"
                        class="imageSize mx-2 mb-4 cursor-pointer relative select-none"
                    >
                        <modal-link
                            route="medias.show"
                            :params="{ id: media.file_uuid }"
                        >
                            <img
                                :src="media.thumbnail_url"
                                class="h-full mx-auto"
                            >
                        </modal-link>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="!community"
            class="mt-3 border border-blue-400 font-400 text-sm text-blue-800 font-frank px-4 py-6 bg-blue-200 text-center"
        >
            <p>please select a community to continue.</p>
        </div>
    </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin';
import Auth from '@/mixins/Auth';
import { mapActions, mapGetters } from 'vuex';
import Loader from '@/components/ui/Loader';
import NotifyMixin from '@/mixins/NotifyMixin';
import ModalNavigation from '@/mixins/ModalNavigation';
import ModalLink from '@/components/ui/modals/ModalLink';
import FileManager from '@/components/ui/filemanager/FileManager';
import EventBus from '@/utils/EventBus';

export default {
    components: { Loader, FileManager, ModalLink },
    mixins: [AuthMixin, Auth, ModalNavigation, NotifyMixin],
    data: () => {
        return {
            loading: false,
            allCommunities: [],
            selectedCommunityId: null,
            medias: [],
            files: [],
            services: [
                { value: 'marketing', label: 'main library' },
                { value: 'cms', label: 'websites' },
                { value: 'dh', label: 'digital human' },
            ],

            selectedService: null,
        };
    },

    computed: {
        ...mapGetters({
            communities: 'auth/communities',
            library: 'files/getAllLibraryFiles',
            geUiMediaGalleryOption: 'files/geUiMediaGalleryOption',
        }),

        filter() {
            let filter = { sort: 'created_at,DESC' };

            if (this.community) filter.community_id = this.community?.id;

            if (this.selectedService) filter.base_path = this.selectedService ? this.selectedService : 'marketing';

            return filter;
        },

        canUpload() {
            return this.community && this.selectedService === 'marketing';
        },
    },

    watch: {
        selectedService: {
            handler: function () {
                this.updateUiMediaGalleryOption({ key: 'base_path', value: this.selectedService });
                this.getMedias();
            },
        },

        community: {
            handler: function () {
                if (this.community) {
                    this.updateUiMediaGalleryOption({ key: 'communityId', value: this.community?.id });
                    this.getMedias();
                }
            },
        },
    },

    created() {
        this.communities.forEach(community => this.allCommunities.push({ key: community.id, value: community.name }));
        this.selectedService = this.geUiMediaGalleryOption('base_path');
        this.selectedCommunityId = this.community ? this.community.id : this.geUiMediaGalleryOption('communityId');

        EventBus.on('data-updated', () => {
            this.getMedias();
        });
    },

    methods: {
        ...mapActions({
            updateUiMediaGalleryOption: 'files/updateUiMediaGalleryOption',
        }),

        getMedias() {
            this.loading = true;

            this.$filemanagerDataProvider
                .get('files', this.filter)
                .then(response => {
                    this.medias = response.data;
                })
                .catch(() => {
                    this.notifyError('Error while fetching the medias');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.btn-filemanager {
    background: var(--highlightColor200);
    border: 1px solid var(--highlightColor300);
    padding: 1rem 1.5rem;
    color: var(--highlightColortextSmall);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-filemanager svg {
    width: 22px;
    height: 22px;
    display: block;
    margin-right: 8px;
}

.btn-filemanager_small {
    color: var(--highlightColortextSmall);
    font-weight: 600;
    text-decoration: underline;
    padding-bottom: 1rem;
}
.btn-filemanager_small:hover {
    color: var(--highlightColor700);
}
.imageSize {
    width: 7.5rem;
    height: 7.5rem;
}
</style>
